import {
  computed,
  ref,
  watch,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import bedStoreModule from '../bedStoreModule';

export default function useBedDetailModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'bed';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, bedStoreModule);
  }

  const toastification = toast();
  const refModalDeleteCashbook = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(null);
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(null);
  };
  const isLoading = ref(false);

  const resetModal = () => {
    resetItemLocal();
  };

  const bedId = computed(() => props.bedId);

  const fetchBedDetail = () => {
    if (bedId.value) {
      isLoading.value = true;
      store.dispatch('bed/getBedDetail', { bedId: bedId.value, isPublic: props.isPublic })
        .then(response => {
          itemLocal.value = response.data;
          isLoading.value = false;
        })
        .catch(error => {
          toastification.showToastError(error);
          isLoading.value = true;
        });
    }
  };

  const onOpen = () => {
    resetModal();
    fetchBedDetail();
  };

  const onDeleteAttachment = () => { };

  watch(bedId, val => {

  });

  return {
    refModalDeleteCashbook,
    refForm,
    itemLocal,
    resetItemLocal,
    resetModal,
    fetchBedDetail,
    onOpen,
    isLoading,
    onDeleteAttachment,
  };
}
