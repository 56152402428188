<template>

  <b-link
    v-if="room.beds.length === 0"
    v-b-modal.modal-room-detail
    @click="$emit('view-room-detail')"
  >
    <b-card
      :border-variant="room.status.variant"
      no-body
      class="my-50 room-card"
    >
      <h4 :class="`font-weight-bolder bg-${room.status.variant} text-white text-center py-50 font-medium-1`">

        {{ room.name }}
      </h4>

      <h6
        v-if="room.beds.length === 0"
        :class="`px-50 text-center font-small-3 text-${room.status.variant}`"
      >
        {{ room.status.title }}
      </h6>

    </b-card>
  </b-link>
  <b-card
    v-else
    :border-variant="room.status.variant"
    no-body
    class="my-50 room-card"
  >
    <b-link
      v-b-modal.modal-room-detail
      @click="$emit('view-room-detail')"
    >
      <h4 :class="`font-weight-bolder bg-${room.status.variant} text-white text-center py-50 font-medium-1`">

        {{ room.name }}
      </h4>
    </b-link>

    <b-row
      v-if="room.beds"
      class="match-height px-2"
    >
      <b-col
        v-for="(bed,index) in room.beds"
        :key="index"
        md="12"
        xl="6"
      >
        <bed-item
          :bed="bed"
          @view-bed-detail="$emit('view-bed-detail', bed)"
        />
      </b-col>

    </b-row>

  </b-card>

</template>

<script>
import {
  BRow, BCol, BCard, BLink,
} from 'bootstrap-vue';
import BedItem from './BedItem.vue';

export default {

  components: {
    BedItem,
    BLink,
    BCard,
    BRow,
    BCol,
  },

  setup() {

  },

  props: {
    room: {
      type: Object,
      default: () => {},
    },
  },

};
</script>

<style lang="scss" scoped>
  .room-card {
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 4px 25px 0 rgba(#22292f, 0.25);
    }
  }
</style>
