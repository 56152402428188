<template>
  <div class="">
    <div class="d-flex justify-space-between">
      <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
        {{ floor.name }}
      </div>
    </div>

    <b-row class="match-height">
      <b-col
        v-for="(room,index) in floor.rooms"
        :key="index"
        sm="6"
        md="2"
        xl="2"
      >
        <room-item
          :room="room"
          @view-room-detail="$emit('view-room-detail', room)"
          @view-bed-detail="viewBedDetail"
        />
      </b-col>

    </b-row>

  </div>

</template>

<script>
import { BCol, BRow } from 'bootstrap-vue';
import RoomItem from './RoomItem.vue';

export default {
  components: {
    RoomItem,
    BRow,
    BCol,
  },
  props: {
    floor: {
      type: Object,
      default: () => {},
    },
  },
  computed: {

  },
  methods: {
    viewBedDetail(bed) {
      this.$emit('view-bed-detail', bed);
    },
  },

};
</script>
