<template>

  <b-link
    v-b-modal.modal-bed-detail
    @click="$emit('view-bed-detail', bed)"
  >
    <b-card
      :border-variant="bed.status.variant"
      no-body
      class="my-50"
    >
      <h6 :class="`font-weight-bolder bg-${bed.status.variant} text-white text-center py-50 font-small-2`">
        {{ bed.name }}
      </h6>

      <h6 :class="`px-50 text-center font-small-2 text-${bed.status.variant}`">
        {{ bed.status.title }}
      </h6>
    </b-card>
  </b-link>

</template>

<script>
import {
  BCard,
  BLink,
} from 'bootstrap-vue';

export default {
  components: {
    BCard,
    BLink,
  },
  setup() {

  },

  props: {
    bed: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    getBedVariant() {
      if (this.bed.active) {
        return {
          variant: 'warning',
          status: 'Đang ở',
        };
      }

      return {
        variant: 'warning',
        status: 'Ngưng sử dụng',
      };
    },
  },
};
</script>
